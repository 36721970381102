<template>
  <div class="page-content">
    <breadcrumb :goBack="true" content="Mein Profil"></breadcrumb>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-8 col-md-6 col-lg-4 mx-auto">
          <form class="edit edit-form" action="#!">
            <div class="mb-3">
              <div class="form-group">
                <label class="col-4">Dein Username:</label>
                <input type="text" class="form-control" v-model="credentials.name">
              </div>
              <span class="validation-error" style="color:red" v-show="showNameError">Gib deinen Vor- und Nachnamen ein.</span>
            </div>
            <div class="form-group">
              <label class="col-4">بريدك الإلكتروني</label>
              <input disabled="" type="email" class="form-control" :placeholder="userMail">
            </div>

            <button @click.prevent="updateUser" class="btn btn-hover btn-block btn-block__lg-half btn-active mt-4 logout-btn">قدم طلبك</button>
            <button @click.prevent="showDeleteUserModal=true" class="btn btn-danger btn-block btn-block__lg-half btn-active mt-4 delete-btn">Account löschen</button>
          </form>
        </div>
      </div>
    </div>

    <the-modal v-if="showDeleteUserModal" @closeModal="showDeleteUserModal=false">
      <template v-slot:header="">
        <h5>Account löschen</h5>
      </template>
      <template v-slot:body="">
       <span>  Möchten Sie Ihren Account wirklich endgültig löschen?</span>
     </template>
     <template v-slot:footer="">
      <div class="btn btn-danger" style="border:none" @click="showDeleteUserModal=false, removeUser()">
        Account löschen
      </div>
    </template>
  </the-modal>
</div>
</template>

<script>
  import { showLoader, hideLoader } from '@/utils/helpers';
  import TheModal from '@/components/modals/show.vue';
  import Resource from '@/api/resource';
  const userResource = new Resource('users');


  export default {
    name: 'Profile',
    components: {
      TheModal,
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
    },
    data() {
      return {
        showDeleteUserModal: false,
        showNameError: false,
        credentials: {
          id: '',
          name: '',
          email: '',
        },
      };
    },
    computed:{
      user(){
        return this.$store.state.user;
      },
      userName(){
        return this.$store.state.user.name;
      },
      userMail(){
        return this.$store.state.user.email;
      },
    },
    mounted(){
      this.credentials.id = this.user.id;
      this.credentials.name = this.user.name;
      this.credentials.email = this.user.email;
    },
    methods: {
      showLoader,
      hideLoader,
      validateNameInput(){
        if(this.credentials.name == "" || this.credentials.name.length<4){
          this.showNameError = true;
          return false;
        }
        this.showNameError = false;
        return true;
      },
      logOut(){
        this.loader = this.showLoader(this.loader);
        this.$store.dispatch('logout');

        this.$notify({
          duration: 3500,
          title: "Erfolgreich abgemeldet!",
          text: "Du hast dich von dem Kiel.RefugeeCompass abgemeldet."
        });

        this.loader = this.hideLoader(this.loader);
        this.$router.push({name:'Home'});
      },
      updateUser(e){
        e.preventDefault();
        var nameValid = this.validateNameInput();
        if (nameValid) {
          this.loader = this.showLoader(this.loader);
          userResource.update(this.user.id,this.credentials).then(() => {

            this.$notify({
              duration: 3500,
              title: 'Success!',
              text: 'Data updated.'
            });

          /*Update the User-Data with the Data from the Server*/
            this.$store.dispatch('loadUser');
            this.$router.push({name:'Home'});

          })
          .catch(() => {

           this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Error!',
            text: "Data couldn't be updated, please try again later."
          });

         })
          .finally(() => {
            this.loader = this.hideLoader(this.loader);
          });
        }
      },
      removeUser() {
        this.loader = this.showLoader(this.loader);
      /*und dann das ganze an die APi übergeben*/
        userResource.destroy(this.user.id).then(() => {

          this.$notify({
            duration: 3500,
            title: 'Account deleted!',
            text: 'Your user account has been successfully deleted.'
          });

          this.$store.dispatch('resetUserAndToken');
          this.$router.push({name:'Home'});

        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
        });
      },
    }
  }
</script>
<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';

  .breadcrumb {
  }

  .form-group {
    width: 100%;
  }

  .page-content {
    @media (max-width: 991px) {
      margin-top: 50px;
    }
  }

  .change-pw-btn {
    margin-top: 25px !important;
  }

  .delete-btn {
    background-color: #c82333;
  }

  .change-pw-btn:hover, .logout-btn:hover {
    background-color: #203B73;
  }

  .edit-form {
    @media(max-width: 991px) {
      margin-top: 50px;
    }
  }
</style>